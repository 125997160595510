<template>
    <div class="block-list">
        <div class="return_page">
            <a @click="goBack">
                <i class="fas fa-arrow-left"></i>
                <span class="mobile">
                    {{ $t("vigik.navigation.listAccessType") }}
                </span>
            </a>
        </div>
        <div class="title">{{ $t("vigik.title") }}</div>
        <div class="button-choice">
            <p>{{ $t("vigik.config.confirmPoliticsUpdate") }}</p>
            <div>
                <button @click="refuseUpdates()">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    {{ $t("button.cancel") }}
                </button>
                <button @click="acceptUpdates()">
                    <i class="fa fa-check" aria-hidden="true"></i>
                    {{ $t("button.validate") }}
                </button>
            </div>
        </div>
        <div class="block-content">
            <table>
                <thead>
                    <tr>
                        <th>
                            {{ $t("vigik.custom.activityName") }}
                        </th>
                        <th>
                            {{ $t("vigik.custom.time-range") }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(update, index) in updateContent"
                        :key="`update-table-${index}`"
                    >
                        <td>
                            <router-link
                                :to="`/vigik3/access-type/${id}/${name}/origin/${update.id}`"
                            >
                                {{ update.label }}
                            </router-link>
                        </td>
                        <td>
                            <router-link
                                :to="`/vigik3/access-type/${id}/activity/${update.id}/UpdateSchedule/${update.label}`"
                            >
                                <i class="fas fa-eye"></i>
                            </router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {
    getAccessTypeUpdate,
    refuseAccessTypeUpdate,
    deleteAccessType,
} from "@/services/intratone/accesstype"
export default {
    data() {
        return {
            name: this.$route.params.serviceName,
            id: this.$route.params.serviceId,
            updateContent: [],
        }
    },

    mounted() {
        this.getUpdates()
    },

    methods: {
        async getUpdates() {
            await getAccessTypeUpdate(this.id)
                .then((response) => {
                    this.updateContent = response.data.list
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        async refuseUpdates() {
            await refuseAccessTypeUpdate({ name: this.name }, this.id)
                .then((response) => {})
                .catch((error) => {
                    console.log(error)
                })

            this.$router.push({ path: "/vigik3" })
        },

        async acceptUpdates() {
            await deleteAccessType(this.id).catch((error) => {
                console.log(error)
            })

            this.$router.push({ path: "/vigik3" })
        },

        goBack() {
            window.history.back()
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

@media all and (max-width: 768px) {
    .mobile {
        display: none;
    }
}

.block-list {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: auto;

    @media all and (min-width: 768px) {
        padding: 20px 80px 0px 80px;
        gap: 1.5em;
    }

    @media all and (max-width: 768px) {
        padding: 20px 40px 0px 40px;
        gap: 1em;
    }

    .return_page {
        @media all and (max-width: 768px) {
            margin: 20px;
        }
        @media all and (min-width: 768px) {
            margin: 0 auto;
        }
        width: 100%;
        height: 4%;
        a {
            color: $blue-darker;
            cursor: pointer;
            text-decoration: none;
            transition: color 200ms;
            font-family: "Avenir Heavy";
            font-size: 12px;
            i {
                margin-right: 10px;
                transition: margin 200ms;
            }
        }
        a:hover {
            color: $orange-neutral;
            i {
                margin-right: 15px;
            }
        }
    }

    .title {
        font-size: $veryBig;
        font-weight: 700;
        color: $dark-blue;
        background-color: $grey-neutral;
        color: $orange-neutral;
        text-align: center;
        padding: 0.5em 0;
        border-radius: 10px;

        @media all and (max-width: 768px) {
            font-size: $big;
        }
    }
    .button-choice {
        width: 100%;
        display: flex;
        gap: 1.5em;
        align-items: center;
        justify-content: center;
        padding: 2em 0;

        @media all and (max-width: 768px) {
            flex-direction: column;
            gap: 1em;
        }

        p {
            font-size: $normal;
            font-weight: 700;
            color: $dark-blue;
        }

        div {
            display: flex;
            gap: 1.5em;

            button:nth-child(1) {
                padding: 0.3em 2em;
                display: flex;
                align-items: center;
                gap: 0.5em;
                border-radius: 10px;
                border: none;
                font-size: $normal;
                font-weight: 500;
                background-color: $dark-blue;
                color: $white;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: $blue-light;
                    transition: all 0.2s ease-in-out;

                    i {
                        animation: cancel infinite 0.8s alternate;
                    }
                }
            }

            button:nth-child(2) {
                display: flex;
                align-items: center;
                gap: 0.5em;
                padding: 0.3em 2em;
                border-radius: 10px;
                border: none;
                font-size: $normal;
                font-weight: 500;
                background-color: $orange-neutral;
                color: $white;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &:hover {
                    background-color: $orange-light;
                    transition: all 0.2s ease-in-out;

                    i {
                        animation: validate infinite 0.8s alternate;
                    }
                }
            }
        }
    }
    .block-content {
        width: 100%;
        display: flex;
        justify-content: center;

        table {
            width: 100%;
        }

        table th {
            font-size: $normal;
            color: $grey-darker;
        }

        table th,
        td {
            border-bottom: 1px solid $grey-neutral;
            padding: 0.5em 0;
        }

        table td {
            padding-left: 0.5em;
            transition: all 0.2s ease-in-out;
        }

        table tbody tr {
            padding-left: 0.5em;
            transition: all 0.2s ease-in-out;
            cursor: pointer;
            &:hover {
                transition: all 0.2s ease-in-out;
                background-color: $grey-neutral;
            }

            a {
                color: $dark-blue;
                transition: all 0.2s ease-in-out;
                text-decoration: none;

                &:hover {
                    color: $blue-white;
                    transition: all 0.2s ease-in-out;
                }
            }
        }
    }
}

.block-list h1,
p {
    text-align: center;
    margin: 0;
}

@keyframes cancel {
    0% {
        transform: translate(0px, 0px);
    }
    20% {
        transform: translate(-5px, 0px);
    }
    40% {
        transform: translate(5px, 0px);
    }
    60% {
        transform: translate(-3px, 0px);
    }
    80% {
        transform: translate(3px, 0px);
    }
    100% {
        transform: translate(-2px, 0px);
    }
}

@keyframes validate {
    0% {
        transform: translate(0px, 0px);
    }
    20% {
        transform: translate(0px, -8px);
    }
    40% {
        transform: translate(0px, 0px);
    }
    60% {
        transform: translate(0px, -5px);
    }
    80% {
        transform: translate(0px, 0px);
    }
    100% {
        transform: translate(0px, -2px);
    }
}
</style>
